const defaultConf = require('./config-default');
const BO_URL = 'velizy4.0-data.ms-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL,
  // no trailing '/'
  BO_PATH: "~/printemps/printemps-velizy/".concat(BO_URL),
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  // "legacy", // 'www',
  BO_SSH_PORT: '43122',
  // '22'

  DEPLOY_PATH: '~/printemps/printemps-velizy/velizy.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID_V4: 'G-REQQ08L730',
  projectId: 217901982433,
  // GCM/FCM send id
  appId: '0A4B3-E297E',
  // pushwoosh appid

  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'https://services.mobile-spot.com/api/exportNotesByMail',
    // 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  TOU_LINK: {
    en: "https://".concat(BO_URL, "/cgu/cgu_en.html"),
    fr: "https://".concat(BO_URL, "/cgu/cgu_fr.html"),
    zh: "https://".concat(BO_URL, "/cgu/cgu_zh.html")
  },
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt")
});